import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { PerspectiveCamera, PointerLockControls } from '@react-three/drei';
import * as THREE from 'three';

// Component for each card that faces the camera
function Card({ position }) {
  const cardRef = useRef();
  const { camera } = useThree();

  // Make the card face the camera
  useFrame(() => {
    if (cardRef.current) {
      cardRef.current.lookAt(camera.position);
    }
  });

  return (
    <mesh ref={cardRef} position={position}>
      <boxGeometry args={[1, 1.5, 0.01]} /> {/* Thin depth */}
      <meshStandardMaterial color="white" />
    </mesh>
  );
}

function Scene() {
  const cameraRef = useRef();
  const { camera } = useThree();

  // Set sky-ground gradient background
  useThree(({ scene }) => {
    const skyColor = new THREE.Color(0xadd8e6); // Light blue for the sky
    const horizonColor = new THREE.Color(0xf4a460); // Light brown for horizon
    const groundColor = new THREE.Color(0xadd8e6); // Light blue for ground

    const gradientCanvas = document.createElement('canvas');
    gradientCanvas.width = 1;
    gradientCanvas.height = 256;
    const ctx = gradientCanvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 256);
    gradient.addColorStop(0, `#${skyColor.getHexString()}`);
    gradient.addColorStop(0.5, `#${horizonColor.getHexString()}`);
    gradient.addColorStop(1, `#${groundColor.getHexString()}`);
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 1, 256);

    const texture = new THREE.CanvasTexture(gradientCanvas);
    scene.background = texture;
  });

  // Camera movement and smooth movement state
  const movement = useRef({ forward: false, backward: false, left: false, right: false });
  const velocity = useRef(new THREE.Vector3());
  
  useFrame(() => {
    const speed = 0.02; // Reduced speed for smoother, slower movement
    const damping = 0.85; // Damping for smooth deceleration
    const direction = new THREE.Vector3();

    // Determine movement direction based on camera orientation
    if (movement.current.forward) direction.z -= speed;
    if (movement.current.backward) direction.z += speed;
    if (movement.current.left) direction.x -= speed;
    if (movement.current.right) direction.x += speed;

    direction.applyQuaternion(camera.quaternion); // Apply camera's rotation to movement
    velocity.current.add(direction).multiplyScalar(damping); // Apply damping for smoothness

    cameraRef.current.position.add(velocity.current);
  });

  // Key press events for movement
  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowUp':
      case 'w':
        movement.current.forward = true;
        break;
      case 'ArrowDown':
      case 's':
        movement.current.backward = true;
        break;
      case 'ArrowLeft':
      case 'a':
        movement.current.left = true;
        break;
      case 'ArrowRight':
      case 'd':
        movement.current.right = true;
        break;
      default:
        break;
    }
  };

  const handleKeyUp = (event) => {
    switch (event.key) {
      case 'ArrowUp':
      case 'w':
        movement.current.forward = false;
        break;
      case 'ArrowDown':
      case 's':
        movement.current.backward = false;
        break;
      case 'ArrowLeft':
      case 'a':
        movement.current.left = false;
        break;
      case 'ArrowRight':
      case 'd':
        movement.current.right = false;
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // Generate positions for 20 cards around the camera
  const cardPositions = Array.from({ length: 20 }).map((_, i) => {
    const angle = (i / 20) * Math.PI * 2;
    const distance = 10;
    const x = Math.cos(angle) * distance;
    const z = Math.sin(angle) * distance;
    return [x, 0, z];
  });

  return (
    <>
      {/* POV Camera */}
      <PerspectiveCamera ref={cameraRef} makeDefault position={[0, 1.5, 5]} fov={75} />
      {/* Pointer lock for game-like controls */}
      <PointerLockControls />
      {/* 20 paper-like cards facing the camera */}
      {cardPositions.map((pos, i) => (
        <Card key={i} position={pos} />
      ))}
      {/* Lighting */}
      <ambientLight intensity={0.5} />
      <directionalLight position={[10, 10, 5]} intensity={1} />
    </>
  );
}

export default function App() {
  return (
    <Canvas style={{ width: '100vw', height: '100vh' }}>
      <Scene />
    </Canvas>
  );
}
